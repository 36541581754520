import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import moment from "moment";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";

export const DailyLogs = ({ id }) => {
  const [data, setData] = useState([]);

  const handleDailyAttendance = () => {
    const currentDate = new Date();

    axios
      .post(baseUrl + "/dailyAttendance", {
        id: id,
        date: currentDate,
      })
      .then((res) => {
        setData([res.data.result]);
      })
      .catch((err) => {});
  };
  console.log("data attendance", data);

  return (
    <Dialog>
      <DialogTrigger>
        <button onClick={handleDailyAttendance} className="button-17">
          View
        </button>
      </DialogTrigger>
      <DialogContent>
        {data && (
          <div>
            <div
              style={{
                padding: "20px",
                textAlign: "center",
                border:'2px solid grey',
                minHeight:'150px'
              }}
            >
              <table>
                <thead className="border-b-[1px] border-[grey]">
                  <tr
                    style={{
                      backgroundColor: "#f1f4f7",
                      color: "gray",
                    }}
                  >
                    <th>CheckIn</th>
                    <th></th>
                    <th></th>
                    <th>CheckOut</th>
                    <th>Duration</th>

                  </tr>
                </thead>
                <tbody className="tableBody">
                 
                  {data?.map((log, index) => {
                    const maxPairs = Math.max(
                      log.loggedIn?.length || 0,
                      log.loggedOut?.length || 0
                    );

                    const pairs = Array.from({ length: maxPairs }, (_, i) => ({
                      loggedIn: log.loggedIn?.[i] || null,
                      loggedOut: log.loggedOut?.[i] || null,
                      duration:log.duration?.[i]||null,
                    }));

                    return pairs.map((pair, i) => (
                      <tr key={`${index}-${i}`} className="border-b-[1px] border-[grey]">
                        <td>
                          {pair.loggedIn
                            ? moment(pair.loggedIn).format("DD-MM-YYYY, LT")
                            : "No CheckIn"}
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          {pair.loggedOut
                            ? moment(pair.loggedOut).format("DD-MM-YYYY, LT")
                            : "No CheckOut"}
                        </td>
                        <td>{(pair.duration/60).toFixed(3)} hrs</td>
                      </tr>
                    ));
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
