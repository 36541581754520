import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import useClientHook from "../../hooks/useClientHook";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "../../components/ui/dialog";

import { State } from "country-state-city";
import axios from "../../config/axiosAuth.js";
import { Button } from "../../components/ui/button.jsx";
import { AddIcon } from "../../components/ui/icons.jsx";
import { useNavigate } from "react-router-dom";
import { Box, Chip, MenuItem, OutlinedInput, Select } from "@mui/material";
import CreatableSelect from "react-select/creatable";
import { baseUrl } from "../../config/baseUrl.js";

export const AddModal = () => {
  const navigate = useNavigate();
  const [modules, getModules] = React.useState([]);

  const { addClientMutation } = useClientHook();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    formState,
    reset,
  } = useForm({
    defaultValues: {
      projectModules: [],
    },
  });

  const getIndianStates = () => {
    const indianStates = State.getStatesOfCountry("IN");
    return indianStates;
  };

  const getStatesOptions = () => {
    const states = getIndianStates();
    return states.map((state) => (
      <option key={state.isoCode} value={state.name}>
        {state.name}
      </option>
    ));
  };

  const { errors } = formState;
  console.log(errors);

  const onSubmit = async (data) => {
    const finaldata = {
      ...data,
      projectModules: data.projectModules.map((item) => item.value),
    };
    const a = await addClientMutation.mutateAsync(finaldata);
    console.log(a.data, "llllllllllllllllll");
    navigate("/addProject", {
      state: {
        ...data,
        clientName: a.data.client._id,
        projectId: a.data.project._id,
        modules: data.projectModules.map((item) => item.value),
      },
    });
    reset();
  };
  const validateDate = () => {
    const startDate = new Date(watch("startDate"));
    const endDate = new Date(watch("endDate"));

    if (startDate > endDate) {
      return "Start date must be lower than end date";
    }

    return true;
  };
  const getProjectModules = async () => {
    try {
      const response = await axios.get(baseUrl + "/getModules");
      console.log("response modules", response);
      getModules(
        response.data.result.map((item) => ({ label: item, value: item }))
      );
    } catch (err) {}
  };
  console.log(modules, "modules");
  useEffect(() => {
    getProjectModules();
  }, []);
  const validateAmountDue = (value) => {
    const amount = parseFloat(watch("amount"));
    const valueAsNumber = parseFloat(value);
    console.log(value, amount);
    if (amount && value) {
      console.log("here");
      console.log(valueAsNumber >= amount);
      return (
        valueAsNumber <= amount ||
        "Amount due cannot be greater than the total amount"
      );
    }
    return true;
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  // const handleChange = (event) => {
  //   console.log('clicked multi module')
  //   const {
  //     target: { value },
  //   } = event;
  //   setValue(
  //     'projectModules',
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };
  return (
    <Dialog>
      <DialogTrigger className="flex text-sm items-center gap-2">
        <span> Add Client</span>
        <AddIcon />
      </DialogTrigger>
      <DialogContent>
        <div>
          <h2>Add Client</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Name is required",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]*$/,
                      message: "Only alphabets are allowed",
                    },
                    maxLength: {
                      value: 15,
                      message: "Maximum 30 characters are allowed",
                    },

                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are allowed",
                    },
                    validate: (value) =>
                      value.trim() !== "" || "Input cannot be only whitespaces",
                  })}
                />
                {errors.name && (
                  <span className="error-message">{errors.name.message}</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="companyName">Company Name</label>
                <input
                  type="text"
                  placeholder="Enter Company Name"
                  {...register("companyName", {
                    validate: (value) =>
                      value.trim() !== "" || "Input cannot be only whitespaces",

                    required: {
                      value: true,
                      message: "Company name is required",
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9\s]*$/,
                      message: "Only alphabets ,numbers are allowed",
                    },
                    maxLength: {
                      value: 50,
                      message: "Maximum 50 characters are allowed",
                    },

                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are allowed",
                    },
                  })}
                />
                {errors.companyName && (
                  <span className="error-message">
                    {errors.companyName.message}
                  </span>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="state">State</label>
                <select
                  {...register("state", {
                    validate: (value) => value !== "Select a state",

                    required: {
                      value: {
                        value: true,
                        message: "State is required",
                      },
                      message: "State is required",
                    },
                  })}
                >
                  <option value="">Select a state</option>
                  {getStatesOptions()}
                </select>

                {errors.state && (
                  <span className="error-message">{errors.state.message}</span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="mobileNumber">Mobile Number</label>
                <input
                  type="text"
                  placeholder="Enter Mobile Number"
                  {...register("mobileNumber", {
                    required: "Mobile number is required",
                    pattern: {
                      value: /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/,
                      message: " Add valid number",
                    },
                    minLength: {
                      value: 10,
                      message: "Minimum 10 characters are allowed",
                    },
                    maxLength: {
                      value: 10,
                      message: "Maximum 10 characters are allowed",
                    },
                    validate: (value) => {
                      if (value.length > 10) {
                        return "Maximum 10 characters are allowed";
                      }
                      return true;
                    },
                  })}
                  onChange={(e) => {
                    const value = e.target.value
                      .replace(/\D/g, "")
                      .slice(0, 10);
                    e.target.value = value;
                  }}
                />
                {errors.mobileNumber && (
                  <span className="error-message">
                    {errors.mobileNumber.message}
                  </span>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="project" style={{ marginTop: "12px" }}>
                  Project Name
                </label>
                <input
                  type="text"
                  placeholder="Enter Project Name"
                  {...register("projectName", {
                    validate: (value) =>
                      value.trim() !== "" || "Input cannot be only whitespaces",

                    required: {
                      value: true,
                      message: "Project name is required",
                    },
                    maxLength: {
                      value: 50,
                      message: "Maximum 50 characters are allowed",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]*$/,
                      message: "Only alphabets are allowed",
                    },
                  })}
                />
                {errors.projectName && (
                  <span className="error-message">
                    {errors.projectName.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="projectModules" style={{ marginTop: "12px" }}>
                  Project Modules
                </label>

                <Controller
                 rules={{
                  validate: (value) =>
                    value.length > 0 || "At least one module must be selected",
                  required: {
                    value: true,
                    message: "Project modules are required",
                  },
                }}
                  render={({ field }) => (
                    <CreatableSelect
                      isMulti
                      isClearable
                      {...field}
                      options={modules}
                    />
                  )}
                  name="projectModules"
                  value={watch("projectModules")}
                  control={control}
                
                />
                {/* <Select
                  multiple
                  value={personName}
                  onChange=  { (e) => handleChange(e)}
                 
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  // renderValue={(selected) => (
                  //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  //     {selected.map((value) => (
                  //       <Chip key={value} label={value} />
                  //     ))}
                  //   </Box>
                  // )}
                 
                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select> */}
                {errors.projectModules && (
                  <span className="error-message">
                    {errors.projectModules.message}
                  </span>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="startDate">Start Date</label>
                <input
                  type="date"
                  {...register("startDate", {
                    required: {
                      value: true,
                      message: "Start date is required",
                    },
                  })}
                />
                {errors.startDate && (
                  <span className="error-message">
                    {errors.startDate.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="endDate">End Date</label>
                <input
                  type="date"
                  min={watch('startDate')}
                  {...register("endDate", {
                    required: {
                      value: true,
                      message: "End date is required",
                    },
                    validate: validateDate,
                  })}
                />
                {errors.endDate && (
                  <span className="error-message">
                    {errors.endDate.message}
                  </span>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="amount">Amount</label>
                <input
                  type="number"
                  placeholder="Enter Amount"
                  {...register("amount", {
                    validate: (value) =>
                      value.trim() !== "" || "Input cannot be only whitespaces",

                    required: {
                      value: true,
                      message: "Minimum Amount is required",
                    },
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Only numbers are allowed",
                    },
                    maxLength: {
                      value: 15,
                      message: "Maximum 15 characters are allowed",
                    },

                    minLength: {
                      value: 1,
                      message: "Minimum 1 characters are allowed",
                    },
                  })}
                />
                {errors.amount && (
                  <span className="error-message">{errors.amount.message}</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="amountDue">Amount Due</label>
                <input
                  type="number"
                  placeholder="Enter Due Amount"
                  {...register("amountDue", {
                    validate: validateAmountDue,

                    required: {
                      value: true,
                      message: "Amount due is required",
                    },
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Only numbers are allowed",
                    },
                    maxLength: {
                      value: 15,
                      message: "Maximum 15 characters are allowed",
                    },

                    minLength: {
                      value: 1,
                      message: "Minimum 1 characters are allowed",
                    },
                  })}
                />
                {errors.amountDue && (
                  <span className="error-message">
                    {errors.amountDue.message}
                  </span>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="email" style={{ marginTop: "12px" }}>
                  Email
                </label>
                <input
                  type="text"
                  placeholder="Enter Your Email"
                  {...register("email", {
                    validate: (value) =>
                      value.trim() !== "" || "Input cannot be only whitespaces",

                    required: {
                      value: true,
                      message: "Email is required",
                    },

                    pattern: {
                      value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                      message: "Invalid email address",
                    },

                    maxLength: {
                      value: 50,
                      message: "Maximum 50 characters are allowed",
                    },

                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are allowed",
                    },
                  })}
                />
                {errors.email && (
                  <span className="error-message">{errors.email.message}</span>
                )}
              </div>
            </div>
            <DialogFooter>
              {formState.isValid && (
                <DialogClose asChild>
                  <Button
                    // onClick={() => navigate("/addProject")}
                    type="submit"
                  >
                    Save
                  </Button>
                </DialogClose>
              )}

              {!formState.isValid && <Button type="submit">Save</Button>}

              <DialogClose asChild onClick={() => reset()}>
                <Button onClick={() => reset()}>Close</Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
