import React, { useEffect, useState } from "react";
import { formatDateTime } from "../../../utils/lib";
import { DataTable } from "../../components/table/Data-Table";
import { Button } from "../../components/ui/button";
import axios from "../../config/axiosAuth";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { AiFillEye } from "react-icons/ai";
import { FaVideo } from "react-icons/fa";
import useGetAllTIckets from "../../hooks/testers/getTickets.hook";
import { AddTicket } from "./AddTicket";
import { Eye, Video } from "lucide-react";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import { baseUrl } from "../../config/baseUrl";
import { projectsAssignedToTesterTableColumns } from "./columns";

const Testing = () => {
  const { testersList } = useEmployeeHook();
  const [selectedTester, setSelectedTester] = useState(null);
  const [projectList, setProjectList] = useState([]);
  console.log(testersList?.[0]?.teamMembers
    , "testersList",testersList);
  const { allTickets } = useGetAllTIckets();

  const fetchProjectsAssaignedToTesterByTesterId = async () => {
    try {
      const response = await axios.post(baseUrl + "/getTesterProject", {
        testerId: selectedTester,
      });
      setProjectList(response?.data.result);
      console.log(response, "response");
    } catch (err) {}
  };

  useEffect(() => {
    fetchProjectsAssaignedToTesterByTesterId();
  }, [selectedTester]);

  return (
    <>
      <div className="flex justify-end max-h-[500px] overflow-y-auto ">
        <select
          style={{
            width: "15vw",
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "auto",
            marginBottom: "10px",
            padding: "5px",
            paddingInline: "10px",
          }}
          value={selectedTester}
          onChange={(e) => setSelectedTester(e.target.value)}
        >
          <option value="0">Select Tester</option>
          {testersList?.[0]?.teamMembers?.map((tester) => (
            <option key={tester._id} value={tester._id}>
              {tester.name}
            </option>
          ))}
        </select>

      </div>
    
      <DataTable
        columns={projectsAssignedToTesterTableColumns}
        data={projectList||[]}
        heading="Testing"
        defaultTheme={true}
        height={"90%"}
        className={"h-[85%]"}
      />

    </>
  );
};

export default Testing;

const TestCommentsModal = ({ comments }) => {
  return (
    <Dialog>
      <DialogTrigger>
        <Button variant="outline" size="sm">
          View
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Comments</DialogTitle>
          <DialogDescription>
            Below are the comments from the users
          </DialogDescription>
        </DialogHeader>
        <div>
          <div style={{ padding: "20px", textAlign: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "2px solid gray",
                marginBottom: "10px",
                alignItems: "center",
              }}
            ></div>
            <table>
              <thead>
                <tr style={{ backgroundColor: "#f1f4f7", color: "gray" }}>
                  <th>Name</th>
                  <th>Comment</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody className="tableBody">
                {comments?.length > 0 ? (
                  comments?.map((comment) => (
                    <tr key={comment?._id}>
                      <td>{comment?.author}</td>
                      <td>{comment?.comment}</td>
                      <td>{formatDateTime(comment?.date)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No Comments</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
