import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import React, { useCallback, useMemo, useState } from "react";
import { AiOutlinePieChart, AiOutlineTeam } from "react-icons/ai";
import { GoReport } from "react-icons/go";
import { HiAdjustments, HiOutlineCollection, HiOutlinePaperAirplane } from "react-icons/hi";
import logo from '../../../Assets/images/whitekicker.png'
import {
  HiOutlineBell,
  HiOutlineChatBubbleBottomCenterText,
  HiOutlineUserPlus,
  HiOutlineUsers,
} from "react-icons/hi2";
import { IoTodayOutline } from "react-icons/io5";
import { PiHandCoinsDuotone } from "react-icons/pi";
import { RiDashboardLine } from "react-icons/ri";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { LuScreenShare } from "react-icons/lu";
import "./sidebar.scss";
import useScreenShareStore from "../../store/ScreenShare";
import LogoutAlert from "../ui/LogoutAlert";
const sideBarData = [
  {
    id: 1,
    lable: "Dashboard",
    icon: <RiDashboardLine />,
    path: "/dashboard",
  },

  {
    id: 2,
    lable: "Client",
    icon: <HiAdjustments />,
    path: "/clients",
  },

  {
    id: 3,
    lable: "Detailed Timesheet",
    icon: <HiOutlineCollection />,
    path: "/detailedTimesheet",
  },
 

  {
    id: 4,
    lable: "Project",
    icon: <AiOutlinePieChart />,
    path: "/project",
  },
  // {
  //   id: 5,
  //   lable: "Meetings",
  //   icon: <AiOutlinePieChart />,
  //   path: "/meetings",
  // },
  // {
  //   id: 15,
  //   lable: "Task Management",
  //   icon: <AiOutlinePieChart />,
  //   path: "/taskManagement",
  // },
  // {
  //   id: 16,
  //   lable: "Project Timeline",
  //   icon: <AiOutlinePieChart />,
  //   path: "/projectTimeline",
  // },
  // {
  //   id: 17,
  //   lable: "Project Calendar",
  //   icon: <AiOutlinePieChart />,
  //   path: "/projectCalendar",
  // },
  // {
  //   id:17,
  //   label:"AddTask",
  //   icon:<AiOutlinePieChart />,
  //   path:"/addTask"

  // },
  {
    id: 5,
    lable: "Team",
    icon: <AiOutlineTeam />,
    path: "/team",
  },
  // {
  //   id: 9,
  //   lable: "Add Employee",
  //   icon: <HiOutlineUsers />,
  //   path: "/employee",
  // },
  {
    id: 10,
    lable: "Testers",
    icon: <HiOutlineUsers />,
    path: "/testing",
  },
  // {
  //   id: 19,
  //   lable: "Responsibility",
  //   icon: <HiOutlineUserPlus />,
  //   path: "/responsibility",
  // },

  {
    id: 18,
    lable: "Lead",
    icon: <AiOutlineTeam />,
    path: "/leads",
  },

  {
    id: 6,
    lable: "Leaves",
    icon: <IoTodayOutline />,
    path: "/leave",
  },
  {
    id: 14,
    lable: "Attendance",
    icon: <IoTodayOutline />,
    path: "/attendance",
  },

  {
    id: 7,
    lable: "Report",
    icon: <GoReport />,
    path: "/adminReport",
  },

  {
    id: 8,
    lable: "Hiring",
    icon: <HiOutlineUserPlus />,
    path: "/newHiring",
  },

  {
    id: 20,
    lable: "Hiring Follow Ups",
    icon: <AiOutlineTeam />,
    path: "/Hiring-followups",
  },
  // {
  //   id: 21,
  //   lable: "Screens",
  //   icon: <AiOutlineTeam />,
  //   path: "/screen",
  // },

  // {
  //   id: 12,
  //   lable: "Task Assigned",
  //   icon: <HiOutlineUsers />,
  //   path: "/taskassigned",
  // },
  {
    id: 13,
    lable: "Payroll",
    icon: <PiHandCoinsDuotone />,
    path: "/payroll",
  },
  // {
  //   id: 10,
  //   lable: "List Notification",
  //   icon: <HiOutlineBell />,
  //   path: "/listNotification",
  // },
  {
    id: 11,
    lable: "Screen Sharing",
    icon: <LuScreenShare />,
    path: "/Screens",
  },
// {
//   id:99,
//   lable:"Meetings",
//   icon: <HiOutlineBell />,
//   path:"/meeting"
// }
  {
    id: 10,
    lable: "Notification",
    icon: <HiOutlineBell />,
    hasChildren: true,
    isOpen: false,
    children: [
      {
        id: 11,
        lable: "Send Notification",
        icon: <HiOutlinePaperAirplane />,
        path: "/sendnotification",
      },
      {
        id: 12,
        lable: "List Notification",
        icon: <HiOutlineChatBubbleBottomCenterText />,
        path: "/listnotification",
      },
    ],
  },
];

export function AdminSIdeBar() {
  const navigate = useNavigate();
const{setCallStarted}=useScreenShareStore()

  const logoutbtn = () => {
    setCallStarted(false)
    localStorage.clear();
    navigate("/");
  };
  const [sideBarDataState, setSideBarData] = useState(sideBarData);

  const handleNotificationToggle = useCallback((id) => {
    setSideBarData((prevData) =>
      prevData.map((item) => {
        if (item.id === id) {
          // Toggle the isOpen state only for the clicked item
          return {
            ...item,
            isOpen: !item.isOpen,
          };
        } else if (item.hasChildren && item.isOpen) {
          // Keep the parent open if it has children and it is already open
          return {
            ...item,
            isOpen: true,
          };
        }
        return item;
      })
    );
  }, []);

  const handleChildItemClick = useCallback(() => {
    const updatedSideBarData = sideBarDataState.map((item) => {
      if (item.hasChildren) {
        return {
          ...item,
          isOpen: false,
        };
      }
      return item;
    });
    setSideBarData(updatedSideBarData);
  }, [sideBarDataState]);

  const memoizedSideBarItems = useMemo(
    () =>
      sideBarDataState.map((item) => (
        <React.Fragment key={item.id}>
          {item.hasChildren ? (
            <div
              className={`collapsible-item ${item.isOpen ? "open" : ""}`}
              onClick={() => handleNotificationToggle(item.id)}
            >
              <span className="icon-wrapper">{item.icon}</span>
              <span className="lable">{item.lable}</span>
              <span className={`arrow ${item.isOpen ? "up" : "down"}`}>
                &#9660;
              </span>
            </div>
          ) : (
            <NavLink
              to={item.path}
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-wrapper">{item.icon}</span>
              <span className="lable">{item.lable}</span>
            </NavLink>
          )}

          {item.hasChildren && item.isOpen && (
            <div className="submenu">
              {item.children.map((child) => (
                <NavLink
                  key={child.id}
                  to={child.path}
                  className="sidebar-link"
                  activeClassName="active"
                  onClick={handleChildItemClick}
                >
                  <span className="icon-wrapper">{child.icon}</span>
                  <span className="lable">{child.lable}</span>
                </NavLink>
              ))}
            </div>
          )}
        </React.Fragment>

        // <NavLink

        //   to={item.path}
        //   className="sidebar-link"
        //   activeClassName="active"
        // >
        //   <span className="icon-wrapper">{item.icon}</span>
        //   <span className="lable">{item.lable}</span>
        // </NavLink>
      )),
    [sideBarDataState, handleNotificationToggle, handleChildItemClick]
  );
  return (
    <div className="sidebar" id="sidebar">
      <div className="top">
        <Link to="/dashboard">
          <img src={logo} alt="" className="logo" />
        </Link>
      </div>
      <div className="sidebar-container">{memoizedSideBarItems}</div>
      <p
        style={{
          marginLeft: "10px",
          marginTop: "10px",
          color: "#fff",
          display: "flex",
          gap: "10px",
          cursor: "pointer",
        }}
        // onClick={logoutbtn}
      >
        <LogoutAlert/>
        {/* <ExitToAppIcon className="icon" />
        <span className="spanfont">Logout</span> */}
      </p>
    </div>
  );
}
