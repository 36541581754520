import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import Loading from "../../pages/protectedPages/Loading";
import CheckIcon from "@mui/icons-material/Check";
import useTaskMangementEditHook from "../../pages/taskmangement/editTaskStatusHook";

const TaskTable = ({
  data,
  isLoading,
  isError,
  currentPage,
  handlePageChange,
}) => {
  const { editTaskMutation } = useTaskMangementEditHook();

  const [action, setAction] = useState("");
  const [id, setId] = useState("");
  const handleActionChange = async (id, action) => {
    setAction(action);
    setId(id);
  };

  const handleStatusChange = async () => {
    console.log(id, action);
    const data = {
      id: id,
      data: {
        status: action,
      },
    };
    editTaskMutation.mutate(data);
  };

  return (
    <>
      <div className="table-container" style={{ padding: "10px" }}>
        <table>
          <thead style={{ color: "#b7b9bd" }}>
            <tr
              style={{
                backgroundColor: "#f1f4f7",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <th>S.No</th>
              <th>Name</th>
              <th>Designation</th>
              <th>Task Name</th>
              <th>Task Start Date</th>
              <th>Task End Date</th>
              <th>Task Status</th>
              <th>Edit Task Status</th>
              <th>Project Name</th>
              <th>Project Start Date</th>
              <th>Project End Date</th>
            </tr>
          </thead>
          <tbody className="tableBody">
            {isError && (
              <tr>
                <td colSpan="5" style={{ textAlign: "center", color: "red" }}>
                  Error loading tasks
                </td>
              </tr>
            )}
            {isLoading && (
              <tr>
                <td colSpan="5" style={{ textAlign: "center" }}>
                  <Loading />
                </td>
              </tr>
            )}
            {!isError && !isLoading && data?.result?.length > 0
              ? data?.result?.map((task, index) => (
                  <tr key={task._id}>
                    <td>{index + 1}</td>
                    <td>{task?.employeeId?.name}</td>
                    <td>{task?.employeeId?.designation?.name}</td>
                    <td>{task.task}</td>
                    <td>{task?.start_date?.substring(0, 10)}</td>
                    <td>{task?.end_date?.substring(0, 10)}</td>
                    <td>{task.status}</td>
                    <td>
                      <div
                        className="action-options"
                        class="flex justify-center items-center gap-2.5"
                        // style={{
                        //   display: "flex",
                        //   justifyContent: "center",
                        //   alignItems: "center",
                        //   gap: "10px",
                        // }}
                      >
                        <select
                          onChange={(e) =>
                            handleActionChange(task?._id, e.target.value)
                          }
                        >
                          {/* ["Pending","InProgress","Completed","Planning", "Approved"] */}
                          <option value="">Select</option>
                          <option value="Pending">Pending</option>
                          <option value="InProgress">InProgress</option>
                          <option value="Completed">Completed</option>
                          <option value="Planning">Planning</option>
                          <option value="Approved">Approved</option>
                        </select>
                        <CheckIcon
                          onClick={handleStatusChange}
                          class="cursor-pointer"
                          style={{
                            cursor: "pointer",
                            color: "#085394",
                            fontSize: "18px",
                          }}
                        />
                      </div>
                    </td>

                    <td>{task?.projectId?.projectName}</td>
                    <td>
                      {task?.projectId?.projectStartDate?.substring(0, 10)}
                    </td>
                    <td>{task?.projectId?.projectEndDate?.substring(0, 10)}</td>
                  </tr>
                ))
              : !isError &&
                !isLoading && (
                  <tr>
                    <td colSpan="12" style={{ textAlign: "center" }}>
                      No data available
                    </td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>
      <Stack spacing={2} className="pagination-container">
        <Typography>Page: {currentPage}</Typography>
        <Pagination
          count={data?.totalPages}
          page={currentPage}
          onChange={(event, value) => handlePageChange(value)}
          color="primary"
        />
      </Stack>
    </>
  );
};

export default TaskTable;
