import { Delete, Trash } from "lucide-react";
import moment from "moment";
import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import ReactSelect from "react-select";
import { SelectCustomStyles } from "../../../utils/styles";
import { IoIosRemoveCircle } from "react-icons/io";

export default ({
  nestIndex,
  control,
  project,
  errors,
  projectEndDate,
  projectStartDate,
  register,
  employeeOptions,
  modules,
  watch,
  setValue,
  removeTeam
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `teams.${nestIndex}.members`,
  });

  
  const deleteTeam = async (teamIndex) => {
   console.log(teamIndex,'entered deleteteam ')
    try {
      remove(teamIndex)
    } catch (err) {
      throw new Error(err);
    }
  };
  return (
    <div>
      {fields.map((item, k) => {
        return (
          <div key={item.id} style={{ marginLeft: 20 }} className="form-row">
            {/* <label>Add Employee </label> */}
            <div className="form-row">
              <Controller
                render={({ field }) => (
                  <ReactSelect
                    styles={SelectCustomStyles}
                    isClearable
                    {...field}
                    options={employeeOptions}
                    placeholder={"Select a Employee ...."}
                    onChange={(val) => field.onChange(val ? val.value : null)}
                    value={
                      employeeOptions?.find(
                        (option) => option.value === field.value
                      ) || null
                    }
                  />
                )}
                name={`teams.${nestIndex}.members.${k}.employeeId`}
                control={control}
              />
            </div>
            <div className="form-group">
              <Controller
                render={({ field }) => (
                  <ReactSelect
                    styles={SelectCustomStyles}
                    isMulti
                    isClearable
                    options={modules}
                    placeholder={"Select modules ...."}
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions
                        ? selectedOptions.map((option) => option.value)
                        : [];
                      field.onChange(selectedValues);
                    }}
                    value={modules.filter(
                      (option) =>
                        field.value && field.value.includes(option.value)
                    )}
                  />
                )}
                name={`teams.${nestIndex}.members.${k}.modules`}
                control={control}
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="StartDate">
      Start Date:
   </label> */}
              <input
                type="date"
                max={moment(projectEndDate).format("YYYY-MM-DD")}
                min={moment(projectStartDate).format("YYYY-MM-DD")}
                id={`teams.${nestIndex}.members.${k}.startDate`}
                name={`teams.${nestIndex}.members.${k}.startDate`}
                value={watch(`teams.${nestIndex}.members.${k}.startDate`)}
                onChange={(e) =>
                  setValue(
                    `teams.${nestIndex}.members.${k}.startDate`,
                    e.target.value
                  )
                }
                {...register(`teams.${nestIndex}.members.${k}.startDate`, {
                  //    validate: validateManagerteamsMemberDate,
                })}
              />

              {errors.managerteamsMemberStartDate && (
                <p className="error">
                  {errors.managerteamsMemberStartDate.message}
                </p>
              )}
            </div>

            <div className="form-group">
              {/* <label htmlFor="EndDate">
    End Date:
   </label> */}
              <input
                type="date"
                max={moment(projectEndDate).format("YYYY-MM-DD")}
                min={watch(`teams.${nestIndex}.members.${k}.startDate`)}
                id={`teams.${nestIndex}.members.${k}.endDate`}
                name={`teams.${nestIndex}.members.${k}.endDate`}
                value={watch(`teams.${nestIndex}.members.${k}.endDate`)}
                onChange={(e) =>
                  setValue(
                    `teams.${nestIndex}.members.${k}.endDate`,
                    e.target.value
                  )
                }
                {...register(`teams.${nestIndex}.members.${k}.endDate`, {})}
              />

              {errors.managerteamsMemberEndDate && (
                <p className="error">
                  {errors.managerteamsMemberEndDate.message}
                </p>
              )}
            </div>

            <button type="button" onClick={() => remove(k)}>
              <IoIosRemoveCircle color={"red"} />
            </button>
          </div>
        );
      })}

<div className="flex gap-4 mb-[10px]">

<button type="button" onClick={() => append({})} className="flex items-center justify-center gap-2">
  <div className="w-[20px] h-[20px] bg-rgb(8 83 148,0.4) flex items-center justify-center rounded-[50%] text-[#eaf2ff]" style={{backgroundColor:'rgb(8,83,148,0.3)'}}>+</div> employee
</button>
<button type="button" onClick={() => removeTeam(nestIndex)}  className="flex items-center justify-center gap-2">
<div className="w-[20px] h-[20px] bg-rgb(8 83 148,0.4) flex items-center justify-center rounded-[50%] text-[#eaf2ff] text-[14px]" style={{backgroundColor:' rgb(255,0,0,0.4)'}} onClick={() => removeTeam(nestIndex)}>-</div> Team
        </button>
</div>

      <hr />
    </div>
  );
};
const style = {
  control: (baseStyles, state) => ({
    ...baseStyles,

    height: "40px",
    border: " 1px solid #ccc",
    borderRadius: "5px",
  }),
};
