import moment from "moment";
import { AddCommentModal } from "../reports/AddCommentModal";
import { CommentDialog } from "../../components/modals/CommentDialog";
import { Link } from "react-router-dom";
import { FileViewer } from "react-file-viewer";
import { PreviewDocument } from "../projects/PreviewUploadedDocumentsDialog";
import { EditStatusModal } from "../../components/modals/EditStatus";
import { Edit } from "lucide-react";
import { getStatusStyle } from "../../../utils/lib";

export const projectsAssignedToTesterTableColumns = [
  {
    header: "S.No",
    accessorKey: "",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },
  {
    header: "Project Name",
    accessorKey: "projectName",
    // cell:({row})=>{
    //     return
    // }
  },
  {
    header: "Assigned from ",
    cell: ({ row }) => {
      return moment(row?.original?.testerTeam?.leaderStartDate).format(
        "DD-MM-YY"
      );
    },
  },
  {
    header: "Assigned Upto",
    cell: ({ row }) => {
      return moment(row?.original?.testerTeam?.leaderEndDate).format(
        "DD-MM-YY"
      );
    },
  },
  {
    header: "Project Status",
    accessorKey: "status",
    cell:({row})=> <span style={getStatusStyle(row.original.status)}>{row.original.status}</span>
  },
  {
    header: "Add Comment",
    cell: ({ row }) => {
      return (
        <AddCommentModal id={row?.original.id} url={"/addTestCommentByAdmin"} />
      );
    },
  },
  {
    header: " Comment",
    cell: ({ row }) => {
      return <CommentDialog id={row?.original?.id} url={"/getTestComments"} />;
    },
  },

  {
    header: "Details",
    accessorKey: "projectName",
    cell: ({ row }) => {
      return (
        <Link to={`/projectTickets/${row?.original?.id}`}>
          <button
            // onClick={handleComment}
            className="button-17 text-xs w-28 !rounded-[10px]"
            variant="outline"
          >
            Details
          </button>
        </Link>
      );
    },
  },
];

const statusOption = [
  { label: "Fixed", value: "Fixed" },
  { label: "Not Fixed",value: "Not Fixed" },
  { label: "Fixed By Frontend, Backend Pending", value: "Fixed By Frontend, Backend Pending" },
  { label: "Fixed By Backend, Frontend Pending" , value: "Fixed By Backend, Frontend Pending" },
  { label: "Working as Expected", value: "Working as Expected" },
  { label: "Not Required",value: "Not Required" },
  { label: "Partially fixed", value: "Partially fixed" },
];

export const testeColumns = [
  {
    header: "S.No",
    cell: ({ row }) => row.index + 1,
  },
  {
    header: "Test Case ID",
    cell: ({ row }) => row?.original?.testCaseId,
  },
  // {
  //   cell: ({ row }) => formatDateTime(row.original?.date),
  //   header: "Date",
  // },
  {
    accessorKey: "module",
    header: "Test Module",
  },
  {
    header: "Assigned To",
    cell: ({ row }) => row.original?.assignedTo?.name,
  },
  {
    header: "Project",
    cell: ({ row }) => row.original?.project?.projectName,
  },
  // {
  //   header: "Test Data",
  //   cell: ({ row }) => formatDateTime(row.original?.date),
  // },
  {
    header: "Description",
    cell: ({ row }) => row.original?.description,
  },
  {
    header: "Bugs",
    cell: ({ row }) => row.original?.bugs,
  },
  {
    header: "Tester Status",
    cell: ({ row }) => (
      <div className="flex">
        {row.original?.testerStatus}
        <EditStatusModal
          trigger={<Edit size={"12px"} />}
          url={"/ticket"}
          invalidateQuery="getTicketsById"
          id={row?.original?._id}
          options={statusOption}
          testerStatus={true}
        />
        
      </div>
    ),
  },
  {
    header: "Developer Status",
    cell: ({ row }) => (
      <div className="flex">
        {row.original?.developerStatus}
        <EditStatusModal
          trigger={<Edit size={"12px"} />}
          url={"/ticket"}
          invalidateQuery="getTicketsById"
          id={row?.original?._id}
          options={statusOption}
          developerStatus={true}

        />
        
      </div>
    ),
  },
  // {
  //   accessorKey: "status",
  //   header: "Tester Status",
  // },
  {
    accessorKey: "expectedOutput",
    header: "Expected Output",
  },
  {
    accessorKey: "actualOutput",
    header: "Actual Output",
  },
  {
    accessorKey: "remarks",
    header: "Remarks",
  },
  {
    accessorKey: "result",
    header: "Result",
  },
  {
    header: "Screenshot",
    // accessorKey:'screenshot'
    cell: ({ row }) =>
      row.original?.screenshot ? (
        <PreviewDocument path={row.original.screenshot} />
      ) : (
        "No-file"
      ),
  },
  {
    header: "Recording",
    cell: ({ row }) =>
      row.original?.recording ? (
        <PreviewDocument path={row.original?.recording} />
      ) : (
        "No-file"
      ),
  },

  // {
  //   header: "Assign Bug",
  //   cell: ({ row }) => (
  //     <span className="flex justify-center">
  //       <UserCheck />,
  //     </span>
  //   ),
  // },

  // {
  //   header: "Comment",
  //   cell: ({ row }) => <TestCommentsModal comments={row.original.comments} />,
  // },
];
