import React, { useState, useEffect } from "react";
import { DataTable } from "../../components/table/Data-Table";
import { MonthlyColumn, monthDetailsColumn } from "./columns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useParams } from "react-router-dom";
import { months } from "../../../utils/lib";
import useAttendanceHook from "./attendance.hook";
import moment from "moment";

const MonthlyAttendance = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [startDate, setStartDate] = useState(new Date(currentYear, selectedMonth - 1, 1));
  const [endDate, setEndDate] = useState(currentDate);

  useEffect(() => {
    // Update startDate to first day of selected month
    const newStartDate = new Date(currentYear, selectedMonth - 1, 1);
    setStartDate(newStartDate);

    // Update endDate
    if (selectedMonth === currentMonth) {
      // If current month, set to current date
      setEndDate(currentDate);
    } else {
      // If not current month, set to last day of selected month
      const lastDay = new Date(currentYear, selectedMonth, 0);
      setEndDate(lastDay);
    }
  }, [selectedMonth, ]);

  const params = useParams();
  const location = useLocation();
  console.log(location, "locatoppppp");
  const body = {
    employeeId: params.id,
    month: selectedMonth,
    year: startDate.getFullYear(),
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD"),
  };
  const { data } = useAttendanceHook(body);
  console.log(params, "datab", data);

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <select
          style={{ width: "10vw" }}
          name="month"
          id="Select"
          value={selectedMonth}
          onChange={handleMonthChange}
        >
          {months?.map((item) => (
            <option value={item.value}>{item.label}</option>
          ))}
        </select>

        <div style={{ width: "10vw" }}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Start Date"
          />
        </div>
        <div style={{ width: "10vw" }}>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="End Date"
          />
        </div>
      </div>
      {data && (
        <DataTable
          data={data ? [data] : []}
          columns={monthDetailsColumn}
          defaultTheme={true}
          TableHeight={"200px"}
          heading="Monthly Over View"
        />
      )}
      <div style={{ marginTop: "2rem" }}>
        <DataTable
          columns={MonthlyColumn}
          data={data?.dailyStatus || []}
          defaultTheme={true}
          fileName={`${location?.state?.data?.name}(${location?.state?.data?.employeeId})`}
          heading="Day Wise details"
          showExport={true}
        />
      </div>
    </>
  );
};

export default MonthlyAttendance;
