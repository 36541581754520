import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";
import { DataTable } from "../../components/table/Data-Table";
const EmployeeColumn = [
    {
      header: "S.No",
      cell: ({ row }) => <span>{row.index+1}</span>
    },
    {
      header: "Employee ID:",
      cell: ({ row }) =>row?.original?.employeeId
          
    },
    {
      header: "Employee Name",
     cell: ({ row }) =>row?.original?.name
    },
    {
      header: "Employee Number",
      cell: ({ row }) =>row?.original?.number
    },
    {
      header: "Employee Email",
      cell: ({ row }) =>row?.original?.email
    },
    {
      header: "Employee Gender",
      cell: ({ row }) =>row?.original?.gender
  },
    {
      header: "Employee WorkMode",
      cell: ({ row }) =>row?.original?.workMode
    },
    {
      header: "Employee Designation",
      cell: ({ row }) =>row?.original?.designation?.name
    },
    
  ];
const EmployeeListByWorkMode = () => {
  const { state } = useLocation();
  const [data, setData] = useState();
  useEffect(() => {
    if (state) {
      fetchDataofPresentEmpoyees();
    }
  }, [state]);

  const fetchDataofPresentEmpoyees = async () => {
    try {
      const response = await axios.post(baseUrl + "/employeeListByWorkMode", {
        workMode: state,
      });
      setData(response?.data?.result);
    } catch (err) {
      throw new Error(err);
    }
  };
  console.log(data,'dataaa')
  return (
    <DataTable
      data={data||[]}
      columns={EmployeeColumn}
      defaultTheme={true}
      heading={`${state} Employees list`}
    />
  );
};

export default EmployeeListByWorkMode;
