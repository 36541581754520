import { useForm ,Controller} from "react-hook-form";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import axios from '../../config/axiosAuth.js'
import { State } from "country-state-city";
import useClientHook from "../../hooks/useClientHook.jsx";
import React, { useState } from "react";
import { useEffect } from "react";
import { EditIcon } from "../../components/ui/icons.jsx";
import { Button } from "../../components/ui/button.jsx";
import CreatableSelect from "react-select/creatable";
import { baseUrl } from "../../config/baseUrl.js";
export const EditClientDialog =React.memo( ({ clients }) => {
  const { register, handleSubmit, watch, trigger, formState, setValue,control } =
    useForm();
    const [modules, getModules] = useState([]);
  const { errors } = formState;

  const getIndianStates = () => {
    const indianStates = State.getStatesOfCountry("IN");
    return indianStates;
  };

  const getStatesOptions = () => {
    const states = getIndianStates();
    return states.map((state) => (
      <option key={state.isoCode} value={state.name}>
        {state.name}
      </option>
    ));
  };

  const { deleteClientMutation, editClientMutation } = useClientHook();

  useEffect(() => {
    if (clients) {
      setValue("name", clients.name);
      setValue("companyName", clients.companyName);
      setValue("state", clients.state);
      setValue("mobileNumber", clients.mobileNumber);
      setValue("projectName", clients.projectName);
      setValue("email", clients.email);
      const startDate = new Date(clients.startDate);
      console.log(startDate, clients);
       setValue("startDate", startDate?.toISOString()?.substring(0, 10));
      const endDate = new Date(clients.endDate);
      console.log(endDate);
       setValue("endDate", endDate.toISOString().substring(0, 10));
      setValue("amount", clients.amount);
      setValue("amountDue", clients.amountDue);
      const selectedModules = modules.filter(
        (option) =>
          clients?.project[0]?.projectModules?.some((item) => item === option.value)
      );
      console.log(selectedModules,'clienthj',modules)
      setValue('projectModules',selectedModules)
    }
  }, [clients, setValue,modules]);

  const isValidate = () => {
    const isValid = trigger();
    return isValid;
  };

  console.log(clients.project[0]?.projectModules,'clientsclients')
  const onSubmit = (data) => {
    const fiterEmptyValues = Object.keys(data).reduce((acc, key) => {
      if (data[key]) {
        acc[key] = data[key]
      
      }
      return acc;
    }, {});
    editClientMutation.mutate({ id: clients._id, data:{ ...fiterEmptyValues,  projectModules:data?.projectModules.map((item)=>item.value)} });
  };

  const getProjectModules = async () => {
    try {
      const response = await axios.get(baseUrl + "/getModules");
      console.log("response modules", response);
      getModules(
        response.data.result.map((item) => ({ label: item, value: item }))
      );
    } catch (err) {}
  };
  console.log(modules, "modules");
  useEffect(() => {
    getProjectModules();
  }, []);

  const handleNumberInput = (event) => {
    let numericValue = event.target.value.replace(/\D/g, "");

    if (numericValue.length > 0 && numericValue.charAt(0) === "0") {
      numericValue = numericValue.slice(1);
    }

    setValue("mobileNumber", numericValue.slice(0, 10));
  };

  const validateDate = () => {
    const startDate = new Date(watch("startDate"));
    const endDate = new Date(watch("endDate"));

    if (startDate > endDate) {
      return "Start date must be lower than end date";
    }

    return true;
  };

  return (
    <Dialog>
      <DialogTrigger>
        <EditIcon className="text-primary " />
      </DialogTrigger>
      <DialogContent>
        <div>
          <h2>Edit Client</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  {...register("name", {
                    pattern: {
                      value: /^[a-zA-Z\s]*$/i,
                      message: "Only alphabets are allowed",
                    },
                    maxLength: {
                      value: 30,
                      message: "Maximum 30 characters are allowed",
                    },

                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are allowed",
                    },
                    validate: (value) =>
                      value?.trim() !== "" ||
                      "Input cannot be only whitespaces",
                  })}
                />
                {errors.name && (
                  <span className="error-message">{errors.name.message}</span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="companyName">Company Name</label>
                <input
                  type="text"
                  name="companyName"
                  id="companyName"
                  {...register("companyName", {
                    pattern: {
                      value: /^[a-zA-Z\s]*$/i,
                      message: "Only alphabets are allowed",
                    },

                    maxLength: {
                      value: 50,
                      message: "Maximum 50 characters are allowed",
                    },

                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are allowed",
                    },
                    validate: (value) =>
                      value.trim() !== "" || "Input cannot be only whitespaces",
                  })}
                />
                {errors.companyName && (
                  <span className="error-message">
                    {errors.companyName.message}
                  </span>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="state">State</label>

                <select name="state" id="state" {...register("state")}>
                  <option value="">{clients?.state}</option>
                  {getStatesOptions()}
                </select>
                {errors.state && (
                  <span className="error-message">{errors.state.message}</span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="mobileNumber">Mobile Number</label>
                <input
                  type="text"
                  name="mobileNumber"
                  id="mobileNumber"
                  maxLength={10}
                  {...register("mobileNumber", {
                    pattern: {
                      value: /^[6-9]\d{9}$/,
                      message: "Only numbers are allowed",
                    },

                    maxLength: {
                      value: 10,
                      message: "Maximum 10 characters are allowed",
                    },

                    minLength: {
                      value: 10,
                      message: "Minimum 10 characters are allowed",
                    },
                  })}
                  minLength={10}
                  onInput={handleNumberInput}
                />

                {errors.mobileNumber && (
                  <span className="error-message">
                    {errors.mobileNumber.message}
                  </span>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label
                  for="Project"
                  style={{
                    marginTop: "12px",
                  }}
                >
                  Project Name
                </label>
                <input
                  type="text"
                  name="project"
                  id="project"
                  {...register("projectName", {
                    pattern: {
                      value: /^[a-zA-Z\s]*$/i,
                      message: "Only alphabets are allowed",
                    },
                    maxLength: {
                      value: 30,
                      message: "Maximum 30 characters are allowed",
                    },

                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are allowed",
                    },

                    validate: (value) =>
                      value.trim() !== "" || "Input cannot be only whitespaces",
                  })}
                />

                {errors.projectName && (
                  <span className="error-message">
                    {errors.projectName.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="projectModules" style={{ marginTop: "12px" }}>
                  Project Modules
                </label>

                <Controller
                 rules={{
                  validate: (value) =>
                    value.length > 0 || "At least one module must be selected",
                  required: {
                    value: true,
                    message: "Project modules are required",
                  },
                }}
                  render={({ field }) => (
                    <CreatableSelect
                      isMulti
                      isClearable
                      {...field}
                      options={modules}
                    />
                  )}
                  name="projectModules"
                  value={watch("projectModules")}
                  control={control}
                
                />
                
                {errors.projectModules && (
                  <span className="error-message">
                    {errors.projectModules.message}
                  </span>
                )}
              </div>
             
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="startDate">Start Date</label>
                <input type="date" {...register("startDate", {})} />
                {errors.startDate && (
                  <span className="error-message">
                    {errors.startDate.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="endDate">End Date</label>
                <input
                  type="date"
                  min={watch('startDate')}
                  {...register("endDate", {
                    validate: validateDate,
                  })}
                />
                {errors.endDate && (
                  <span className="error-message">
                    {errors.endDate.message}
                  </span>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="amount">Amount</label>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  {...register("amount", {
                    maxLength: {
                      value: 15,
                      message: "Maximum 10 characters are allowed",
                    },
                  })}
                />

                {errors.amount && (
                  <span className="error-message">{errors.amount.message}</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="amountDue">Amount Due</label>
                <input
                  type="number"
                  name="amountDue"
                  id="amountDue"
                  {...register("amountDue", {
                    maxLength: {
                      value: 10,
                      message: "Maximum 10 characters are allowed",
                    },

                    minLength: {
                      value: 1,
                      message: "Minimum 1 characters are allowed",
                    },
                  })}
                />

                {errors.amountDue && (
                  <span className="error-message">
                    {errors.amountDue.message}
                  </span>
                )}
              </div>
            
            </div>
            <div className="form-row">
              <div className="form-group">
                <label
                  htmlFor="email"
                  style={{
                    marginTop: "12px",
                  }}
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  {...register("email", {
                    pattern: {
                      value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i,
                      message: "Invalid email address",
                    },

                    maxLength: {
                      value: 50,
                      message: "Maximum 50 characters are allowed",
                    },

                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are allowed",
                    },

                    validate: (value) =>
                      value.trim() !== "" || "Input cannot be only whitespaces",
                  })}
                />

                {errors.email && (
                  <span className="error-message">{errors.email.message}</span>
                )}
             
            </div>
            </div>
            <DialogFooter className="flex justify-between gap-4">
              {formState.isValid && (
                <DialogClose asChild>
                  <Button type="submit">Save</Button>
                </DialogClose>
              )}

              {!formState.isValid && <Button type="submit">Save</Button>}

              <DialogClose asChild>
                <Button
                  onClick={() => {
                    deleteClientMutation.mutate(clients._id);
                  }}
                  isLoading={deleteClientMutation.isLoading}
                >
                  Remove
                </Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
})
