import { toast } from "react-toastify";
import { baseUrl } from "../config/baseUrl";
import axios from "./../config/axiosAuth";

const taskServices = {
  getTasks: async (projectId, page, limit) => {
    const response = await axios.post(`${baseUrl}/getAllTask`, {
      projectId,
      page,
      limit,
    });

    console.log("getTasks response:", response.data.result);
    return response.data;
  },

  getProjectUnderReview: async (projectId, page, limit) => {
    try {
      const response = await axios.post(`${baseUrl}/getUnderReviewProjects`, {
        projectId,
        page,
        limit,
      });

      console.log("getTasks response:", response.data.result);
      return response.data;
    } catch (error) {
      console.error("getTasks error:", error);
    }
  },

  getGraphData: async (id) => {
    try {
      const response = await axios.post(`${baseUrl}/getTaskGraph`, {
        projectId: id,
      });

      console.log("getGraphData response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("getGraphData error:", error);
    }
  },

  getPieData: async () => {
    try {
      const response = await axios.get(`${baseUrl}/getTaskChart`);
      console.log("getPieData response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("getPieData error:", error);
    }
  },

  editTask: async (data) => {
    const response = await axios.put(
      baseUrl + "/editTask/" + data.id,
      data.data
    );

    toast.success("Task Edited Successfully");

    return response.data;
  },
};

export { taskServices };
