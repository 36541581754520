import axios from "../config/axiosAuth";
import { baseUrl } from "../config/baseUrl";

const clientServices = {
  getAllClient: async () => {
    try {
      const response = await axios.get(`${baseUrl}/getAllClient`);
      // console.log(response)
      return response.data.result;
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  },

  addClient: async (data) => {
    try {
      const response = await axios.post(`${baseUrl}/addClient`, data);
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  updateClient: async (id, data) => {
    try {
      const response = await axios.put(`${baseUrl}/updateClient/${id}`, data);
      return response.result;
    } catch (error) {
      console.log(error);
    }
  },

  deleteClient: async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/deleteClient/${id}`);
    } catch (error) {
      console.log(error);
    }
  },
};

export { clientServices };
