import { Eye, MoreHorizontal } from "lucide-react";
import moment from "moment";
import { useEffect, useState } from "react";
import IndeterminateCheckbox from "../../components/table/checkBox";
import axios from "../../config/axiosAuth";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Input } from "../../components/ui/input";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import { EditEmployeeModal } from "../employee/EditEmployeeModal";
import { ResponsibilityModal } from "./ResponsibilityDialog";
import { FaEdit, FaEye, FaTrash, FaUndo } from "react-icons/fa";
import { base, baseUrl } from "../../config/baseUrl";
import { toast } from "react-toastify";
import { EditTeamModal } from "./editTeamModal";
import { useQueryClient } from "react-query";
import { ViewResponsibilities } from "./ViewResponsibilitiesDailog";
import { TeamMemberDetails } from "./TeamMemberDtails";
import { TransferTeamAndDeleteTeam } from "./TransferTeamAndDeleteTeam";
import CommonAlert from "../../components/ui/CommonAlert";
import { Link, useParams } from "react-router-dom";
import { BsEye, BsEyeSlashFill } from "react-icons/bs";

function ActiveEmployee({ employeeId }) {
  const {
    addResponsibilitiesMutation,
    employeeActiveStatusMutation,
    suspendEmployeeTillDateMutation,
    data,
    isLoading,
  } = useEmployeeHook();

  return (
    <AlertDialog>
      <AlertDialogTrigger className="w-full">
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          Active
        </DropdownMenuItem>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Active Employee</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>
          Are you sure you want to active this employee?
        </AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={() => {
              employeeActiveStatusMutation.mutate({
                employeeId: employeeId,
                status: "Active",
              });
            }}
          >
            Active
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
const Password = ({ password }) => {
  const [showPassword, setShowPassword] = useState(false);
  const icon = () => {
    if (showPassword) {
    } else {
      <div onClick={IconClick}></div>;
    }
  };
  const IconClick = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="flex items-center justify-center gap-1 ">
      <div onClick={IconClick}>
        {showPassword ? <BsEye /> : <BsEyeSlashFill />}
      </div>
      <input
        style={{ maxWidth:'140px',width:'130px' }}
        value={password}
        type={showPassword ? "text" : "password"}
        readonly
        className="border-none !w-max-content  outline-none pointer-events-none bg-transparent"
      />
    </div>
  );
};
const InActiveEmployee = ({ employeeId }) => {
  const { employeeActiveStatusMutation } = useEmployeeHook();

  return (
    <AlertDialog>
      <AlertDialogTrigger className="w-full">
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          Inactive
        </DropdownMenuItem>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Inactive Employee</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>
          Are you sure you want to inactive this employee?
        </AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={() => {
              employeeActiveStatusMutation.mutate({
                employeeId: employeeId,
                status: "Inactive",
              });
            }}
          >
            Inactive
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
const DeleteTeam = ({ teamId }) => {
  const queryClient = useQueryClient();

  const handleDeleteTeam = async () => {
    try {
      const response = await axios.post(baseUrl + "/deleteTeam", {
        teamId: teamId,
      });

      await queryClient.invalidateQueries("departmentList");

      return response.data;
    } catch (err) {
      toast.error(
        err.response?.data?.message ||
          "An error occurred while deleting the team"
      );
    }
  };

  return (
    <CommonAlert
      trigger={<FaTrash style={{ color: "red", margin: "auto" }} />}
      onClick={() => handleDeleteTeam()}
      heading={"Delete"}
      text={"Are you sure you want to delete ?"}
    />
  );
};

const SuspendEmployee = ({ employeeId }) => {
  const { suspendEmployeeTillDateMutation } = useEmployeeHook();
  const [date, setDate] = useState("");

  return (
    <AlertDialog>
      <AlertDialogTrigger className="w-full">
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          Suspend
        </DropdownMenuItem>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Suspend Employee</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>
          Are you sure you want to suspend this employee?
        </AlertDialogDescription>

        <div>
          <label
            htmlFor="date"
            className="block text-sm font-medium text-gray-700"
          >
            Suspend Till Date
          </label>
          <div className="mt-1">
            <Input
              type="date"
              name="date"
              onChange={(e) => setDate(e.target.value)}
              value={date}
              id="date"
              min={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>

        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className={date ? "" : "bg-slate-400 hover:bg-slate-500"}
            onClick={() => {
              date &&
                suspendEmployeeTillDateMutation.mutate({
                  employeeId: employeeId,
                  date: date,
                  key: "suspend",
                });
            }}
          >
            Suspend
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
export const columns = [
  {
    id: "select",
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  },
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },
  {
    header: "Department",
    cell: ({ row }) => {
      return <span>{row.original?.teamId?.teamName}</span>;
    },
  },
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "email",
    header: "Email",
  },
  {
    header: "Date Of Birth",
    cell: ({ row }) => {
      return <span>{moment(row.original.dob).format("DD-MM-YYYY")}</span>;
    },
  },
  {
    accessorKey: "salaryPerMonth",
    header: "Salary",
  },
  {
    header: "Gender",
    accessorKey: "gender",
  },
  {
    accessorKey: "workMode",
    header: "Work Mode",
  },

  {
    accessorKey: "employeeId",
    header: "Employee Id",
  },
  {
    accessorKey: "designation",
  cell:({row})=>row.original?.designation?.name
  },
  {
    accessorKey: "role",
    header: "Role",
  },

  {
    accessorKey: "dateOfJoining",
    type: "date",
    header: "Date of Join",
    cell: ({ row }) => {
      const dateOfJoin = row.getValue("dateOfJoining");
      return moment(dateOfJoin).format("DD-MM-YYYY");
    },
  },
  {
    header: "Projects",

    cell: ({ row }) => (
      <Link to={`/employeeProjects/${row.original._id}`}>
        <div>View</div>
      </Link>
    ),
  },
  {
    accessorKey: "createdAt",
    type: "date",
    header: "Created At",
    cell: ({ row }) => {
      const create = row.getValue("createdAt");
      return moment(create).format("DD-MM-YYYY");
    },
  },
  {
    // accessorKey: "password",
    cell: ({ row }) => <Password password={row?.original?.password} />,
    header: "Password",
  },

  {
    header: "Action",
    cell: ({ row }) => {
      const employeeId = row.getValue("employeeId");
      const id = row.original._id;

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem
              onClick={() => navigator.clipboard.writeText(employeeId)}
            >
              Copy Employee ID
            </DropdownMenuItem>
            {/* <ResponsibilityModal selectedEmployeeId={row.original._id} /> */}

            <DropdownMenuSeparator />

            <EditEmployeeModal empToEdit={row.original} />
            <ActiveEmployee employeeId={id} />
            <InActiveEmployee employeeId={id} />

            {/* <DropdownMenuItem>Suspend</DropdownMenuItem> */}

            <SuspendEmployee employeeId={id} />
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];
export const employeeProjectcolumns = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },

  {
    accessorKey: "projectName",
    header: "Project",
  },

  {
    accessorKey: "Project Startdate",
    type: "date",
    header: "Project Startdate",
    cell: ({ row }) => {
      const create = row.getValue("projectStartDate");
      return moment(create).format("DD-MM-YYYY");
    },
  },
  {
    type: "date",
    header: "Project End date",
    cell: ({ row }) => {
      const create = row.getValue("projectEndDate");
      return moment(create).format("DD-MM-YYYY");
    },
  },
  {
    header: "Status",
    accessorKey: "status",
  },

  {
    header: "Action",
    cell: ({ row }) => {
      const employeeId = row.getValue("employeeId");
      const id = row.original._id;

      return <Revoke projectId={row.original?._id} />;
    },
  },
];
const Revoke = ({ projectId }) => {
  const { id } = useParams();
  const data = { projectId: projectId, employeeId: id };
  const { revokeEmployeeProject, fetchEmployeeProjectsMutation } =
    useEmployeeHook();
  const revoke = async () => {
    const response = await revokeEmployeeProject.mutateAsync(data);
    console.log(response);
    fetchEmployeeProjectsMutation(id);
  };
  return <FaUndo color="red" margin="auto" onClick={revoke} />;
};
export const accesReportResponsibiltycolumns = [
  { header: "S.No", cell: ({ row }) => row.index + 1 },
  { header: "Employee", cell: ({ row }) => row.original.employeeId?.name },

  {
    header: "Employee Designation",
    cell: ({ row }) => row.original.employeeId?.designation?.name,
  },
  {
    header: "Access List",
    cell: ({ row }) => (
      <>
        {row.original.accessType.map((item, index) => (
          <span key={index}>
            {item}
            {index < row.original.accessType.length - 1 ? ", " : ""}
          </span>
        ))}
      </>
    ),
  },
];

export const acessReportsColumn = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Employee Id",
    accessorKey: "employeeId",
  },
  {
    header: "Responsibilities",
    cell: ({ row }) => {
      const responsibility = row?.original?.rights;
      return (
        <>
          {responsibility ? (
            <ViewResponsibilities
              straightData={responsibility}
              columnData={accesReportResponsibiltycolumns}
              heading={`List of employees whose Access Reoprts are shared to ${row?.original?.name}`}
            />
          ) : (
            "No responsibilities available"
          )}
        </>
      );
    },
  },
];

export const ResponsibilityColumns = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Employee Id",
    accessorKey: "employeeId",
  },
  {
    header: "Responsibilities",
    cell: ({ row }) => {
      const responsibility = row?.original?.responsibility;
      return (
        <>
          {responsibility ? (
            <ViewResponsibilities data={responsibility} />
          ) : (
            "No responsibilities available"
          )}
        </>
      );
    },
  },
];
export const DepartmentColumns = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },
  {
    header: "Department Name",
    accessorKey: "teamName",
  },
  {
    header: "Members",
    cell: ({ row }) => (
      <TeamMemberDetails
        data={row.original?.teamMembers}
        team={row.original?.teamName}
        trigger={row.original?.teamMembers?.length}
      />
    ),
  },
  {
    header: "View",
    cell: ({ row }) =>
      row.original?.teamMembers.length > 0 ? (
        <TeamMemberDetails
          data={row.original?.teamMembers}
          team={row.original?.teamName}
          trigger={<Eye size={20} color={"lightgrey"} />}
        />
      ) : (
        "No-members"
      ),
  },
  // {
  //   header: "Edit",
  //   accessorKey: "employeeId",
  //   cell: ({ row }) => (
  //    <EditTeamModal id={row.original?._id}  />
  //   ),
  // },
  {
    header: "Delete",
    cell: ({ row }) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {row.original?.teamMembers.length > 0 ? (
          <TransferTeamAndDeleteTeam
            teamToBeDeleted={row.original?.teamName}
            teamId={row?.original?._id}
          />
        ) : (
          <DeleteTeam teamId={row?.original?._id} />
        )}
      </div>
    ),
  },
];
