import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { projectServices } from "../service/projectService";
import useProjectStore from "../store/projectStore";

const useProjectHook = (params = {}) => {
  const fetchProjects = async (term) => {
    const data = await projectServices.getAllProjects(term);
    console.log("projectsdtaa, term", data);
    return data.project;
  };

  const fetchPendingProjects = async () => {
    const data = await projectServices.getPendingProjects();
    return data;
  };

  const fetchCompletedProjects = async () => {
    const data = await projectServices.getCompletedProjects();
    return data;
  };

  const fetchModules = async () => {
    const data = await projectServices.getDefaultModules();
    return data;
  };
  const updateTeamMutation = useMutation(
    (data) => {
      return projectServices.updateTeam(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("oneProject");
      },
    }
  );
  const fetchTasks = async () => {
    const data = await projectServices.getTasks();
    return data;
  };
const getProjectSummary=async()=>{
  const response=await projectServices.getProjectSummary(params)
    return response.data
  
}
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useQuery(
    ["projects", params.searchTerm],
    () => fetchProjects(params.searchTerm || ""),
    {
      onSuccess: (data) => {
        setProjects(data);
        setTotalProjects(data.length);
      },
    }
  );
 
  const{data:projectSummary}=useQuery(
    ["projectSummary",params.status,params.projectManager,params.projectName],()=>getProjectSummary()
  )
  const { data: tasks } = useQuery("taskManagement", fetchTasks);

  const {
    setProjects,
    setTotalProjects,
    setPendingProjects,
    setTotalPendingProjects,
    totalPendingProjects,
    totalProjects,
    totalCompletedProjects,
    setTotalCompletedProjects,
    setCompletedProjects,
  } = useProjectStore();

  const { data: ProjectModules } = useQuery("projectModules", fetchModules);

  // const {
  //   data: pendingData,
  //   isLoading: pendingIsLoading,
  //   isError: pendingIsError,
  // } = useQuery("pendingProjects", fetchPendingProjects, {
  //   onSuccess: (data) => {
  //     setPendingProjects(data);
  //     setTotalPendingProjects(data.length);
  //   },
  // });

  const {
    data: completedData,
    isLoading: completedIsLoading,
    isError: completedIsError,
  } = useQuery("completedProjects", fetchCompletedProjects, {
    refetchOnWindowFocus: false,

    onSuccess: (data) => {
      setCompletedProjects(data.project);
      setTotalCompletedProjects(data.count);
    },
  });

  const addProjectMutation = useMutation(
    (data) => {
      return projectServices.addProject(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("projects");
        navigate("/project");
      },
    }
  );

  const addEmployeesInProject = useMutation(
    (data) => {
      console.log(data, "hook");
      return projectServices.addEmployeesInProject(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("projects");
        queryClient.invalidateQueries("projectSummary");

        // toast.success("Employees added successfully");
        navigate("/projects");
      },
    }
  );

  const editProjectMutation = useMutation(
    (data) => {
      return projectServices.editProject(data.id, data.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("projects");
        queryClient.invalidateQueries("projectSummary");
       setTimeout(()=>{

         navigate("/project");
       },1000) // toast.success("Project updated successfully");
      },
    }
  );

  const deleteProjectMutation = useMutation(
    (id) => {
      return projectServices.deleteProject(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("projects");
        // toast.success("Project deleted successfully");
      },
    }
  );

  return {
    data,
    isLoading,
    isError,
    // pendingData,
    // pendingIsLoading,
    // pendingIsError,
    totalPendingProjects,
    totalProjects,
    editProjectMutation,
    deleteProjectMutation,
    addProjectMutation,
    completedData,
    completedIsLoading,
    completedIsError,
    totalCompletedProjects,
    addEmployeesInProject,
    ProjectModules,
    tasks,
    updateTeamMutation,
    projectSummary,
  };
};

export default useProjectHook;
