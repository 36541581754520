import { useState } from "react";
import useTimesheetHook from "../../hooks/useTimesheerHook";
import { Pagination, Typography, Stack } from "@mui/material";
import { DataTable } from "./Data-Table";
import { useLocation } from "react-router-dom";

const BirthdayTable = () => {
  const state=useLocation()
  console.log(state,state?.state?.data)

  const { birthday } = useTimesheetHook();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(3);
  const totalPages = Math.ceil(birthday?.length / pageSize);
const columns=[{header:'S.No',cell:({row})=>row.index+1},
  {header:'Employee Name',cell:({row})=>row.original?.name},
  {header:'Designation',cell:({row})=>row.original?.designation?.name},
  {header:'DOB',cell:({row})=>row.original?.dob?.substring(0, 10)},
]
  

  return (
    <>
           <DataTable data={(state?.state?state?.state?.data:birthday)||[]} columns={columns} defaultTheme={true} TableHeight={'200px'} heading={"BirthDays"}/>
    </>
  );
};

export default BirthdayTable;
