// import axios from "";
import axios from "../../config/axiosAuth";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import { baseUrl } from "../../config/baseUrl";
import { Eye } from "lucide-react";

import ReactSelect from "react-select";
import { GoTasklist } from "react-icons/go";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

export const AssignModule = ({ file, projectId,previousModules }) => {
  const [open, setOpen] = useState(false);
  const [project, setProject] = useState([]);
  const [modules, setModules] = useState([]);
  const { watch, control, handleSubmit, register ,setValue} = useForm();
  const params = useParams();
  const id = params.projectId;

  useEffect(() => {
    getProjectById();
  }, [projectId]);
console.log(projectId,'projectId')
  const getProjectById = async () => {
    try {
      const response = await axios.get(`${baseUrl}/getProjectById/${projectId||id}`);
      console.log(response, "getProjectById");
      setProject(response.data);
    } catch (err) {}
  };

  useEffect(() => {
    if (project) {
      console.log(project, "modules");
      setModules(
        project?.projectModules?.map((item) => ({ label: item, value: item }))
      );
    }
  }, [project]);

  useEffect(()=>{
    const selectedModules = modules?.filter(
      (option) =>
        previousModules?.some((item) => item === option.value)
    );
    setValue('modulesList',selectedModules)
  },[modules])

  const onSubmit = (data) => {
    console.log(data, "submitdata");

    const formData = {
      fileUrl: file,
      projectId: projectId||id,
      moduleType: data.modulesList.map((item) => item.value),
    };
    axios
      .post(baseUrl + "/update-module", formData, {})

      .then((res) => {
        console.log(res.data);
        setOpen(false)
      })

      .catch((err) => {
        console.log(err, "projecterrorr");
      });
  };
  return (
    <Dialog
      className=" min-w-[90%] min-h-[90%]"
      open={open}
      onOpenChange={setOpen}
    >
      <DialogTrigger asChild>
        <div className="w-full flex items-center justify-center min-w-[90%] min-h-[90%]">
          <GoTasklist />
        </div>
      </DialogTrigger>
      <DialogContent className="min-w-[500px] min-h-[250px]">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col items-center justify-evenly"
        >
          <div className="text-lg font-bold">
            Assign Modules to the selected document !!
          </div>
          <div className="w-full flex gap-2 items-center">
            <label htmlFor="modulesList">Select Modules:</label>

            <Controller
              render={({ field }) => (
                <ReactSelect
                  isMulti
                  isClearable
                  {...field}
                  options={modules}
                  placeholder={"Select modules to assign ...."}
                />
              )}
              name="modulesList"
              value={watch("modulesList")}
              control={control}
            />
          </div>
          <button
            style={{
              backgroundColor: "#085394",
              color: "white",
              border: "none",
              width: "6vw",
              borderRadius: "5px",
              marginTop: "5px",
              height: "45px",
              minWidth: "150px",
            }}
            type="submit"
          >
            save
          </button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
