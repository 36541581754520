import { State } from "country-state-city";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { DropdownMenuItem } from "../../components/ui/dropdown-menu";
import { Input } from "../../components/ui/input";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import useEmployeeStore from "../../store/employeeStore";

export const EditEmployeeModal = ({ empToEdit }) => {
  const { register, handleSubmit, setValue, formState,watch } = useForm();
  const { departmentList } = useEmployeeHook();

  const { errors } = formState;

  const { deleteEmployee } = useEmployeeStore();

  const { editEmployeeMutation, deleteEmployeeMutation ,designationList} = useEmployeeHook();

  const getIndianStates = () => {
    const indianStates = State.getStatesOfCountry("IN");
    return indianStates;
  };

  const getStatesOptions = () => {
    const states = getIndianStates();
    return states.map((state) => (
      <option key={state.isoCode} value={state.name}>
        {state.name}
      </option>
    ));
  };
  const getDepartmentOptions = () => {
    return departmentList.map((item) => (
      <option key={item._id} value={item._id}>
        {item.teamName}
      </option>
    ));
  };
  console.log(empToEdit, "empToEdit");
  useEffect(() => {
    if (empToEdit) {
      setValue("employeeId", empToEdit?.employeeId);
      setValue("email", empToEdit?.email);
      setValue("name", empToEdit?.name);
      const dobDate = new Date(empToEdit?.dob);
      setValue("dob", dobDate.toISOString().substring(0, 10));
      setValue("number", empToEdit?.number);
      setValue("state", empToEdit?.state);
      setValue("salaryPerMonth", empToEdit?.salaryPerMonth);
      setValue("designation", empToEdit?.designation?.name);
      setValue("role", empToEdit?.role);

      const team = departmentList?.find(
        (item) => item._id === empToEdit?.teamId?._id
      );
      console.log(team, "teamin rnen", empToEdit?.teamId?._id);
      setValue("teamName", team?._id);
    }
  }, [empToEdit, setValue, departmentList]);

  const onSubmit = (data) => {
    const EditedEmployee = {
      employeeId: data.employeeId,
      email: data.email,
      name: data.name,
      dob: data.dob,
      number: data.number,
      designation: data.designation,
      state: data.state,
      salaryPerMonth: data.salaryPerMonth,
      role: data.role,
      teamName: data.teamName,

    };

    editEmployeeMutation.mutate({ data: EditedEmployee, id: empToEdit._id });
  };
  const heirarchy=[
    {label:'Intern'},
    {label:'Trainee'},
    {label:'Junior'},
    {label:'Senior'},
   { label:'Team Lead'},
  {  label:'Assistant Manager'
  }, { label:'Manager',}
  ]
  const onDelate = () => {
    deleteEmployeeMutation.mutate(empToEdit._id);
    deleteEmployee(empToEdit._id);
  };

  return (
    <Dialog>
      <DialogTrigger className="w-full">
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          Edit
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Employee</DialogTitle>
        </DialogHeader>
        <DialogDescription>Edit the details of the employee</DialogDescription>

        <form onSubmit={handleSubmit(onSubmit)}>
          <label className="form-label">Employee Id </label>
          <Input
            type="text"
            id="employeeId"
            name="employeeId"
            {...register("employeeId", {
              pattern: {
                value: /^[a-zA-Z0-9]+$/,
                message:
                  "Only alphanumeric values are allowed, and no white spaces are allowed",
              },

              validate: (value) =>
                value?.trim() !== "" || "Input cannot be only whitespaces",

              maxLength: {
                value: 30,
                message: "Maximum 30 characters allowed",
              },
              minLength: {
                value: 5,
                message: "Minimum 5 characters required",
              },
            })}
          />
          {errors.employeeId && (
            <p className="error-message">{errors.employeeId.message}</p>
          )}
          <label className="form-label">Email</label>
          <Input
            type="text"
            name="email"
            className="form-control"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Please enter a valid email",
              },
              validate: (value) =>
                value.trim() !== "" || "Input cannot be only whitespaces",
            })}
          />

          {errors.email && (
            <span className="error-message">{errors.email.message}</span>
          )}
          <label className="form-label">Employee Name</label>
          <Input
            type="text"
            id="name"
            name="name"
            {...register("name", {
              validate: (value) =>
                value.trim() !== "" || "Input cannot be empty",

              pattern: {
                value: /^[a-zA-Z\s]*$/i,
                message: "Only alphabets are allowed",
              },

              maxLength: {
                value: 30,
                message: "Maximum 30a characters allowed",
              },
              minLength: {
                value: 3,
                message: "Minimum 3 characters required",
              },
            })}
          />
          {errors.name && (
            <p className="error-message">{errors.name.message}</p>
          )}
          <label className="form-label">Date of Birth</label>
          <Input
            type="date"
            name="dob"
            className="form-control"
            {...register("dob", {})}
            max={new Date().toISOString().substring(0, 10)}
          />

          {
            <p className="error-message">
              {errors.dob?.type === "max" && "Invalid Date"}
            </p>
          }

          <label className="form-label">State</label>
          <select name="state" className="form-control" {...register("state")}>
            <option value={empToEdit?.name}>{empToEdit?.state}</option>
            {getStatesOptions()}
          </select>
          <label className="form-label">Department</label>
          <select
            name="teamName"
            className="form-control"
            {...register("teamName")}
          >
            <option value={""}>Select department</option>
            {getDepartmentOptions()}
          </select>

          <label className="form-label">Mobile number</label>
          <Input
            type="number"
            name="number"
            className="form-control"
            {...register("number", {
              pattern: {
                value: /^[0-9\b]+$/i,
                message: "Only numbers are allowed",
              },
              maxLength: {
                value: 10,
                message: "Maximum 10 characters allowed",
              },

              minLength: {
                value: 10,
                message: "Minimum 10 characters required",
              },
            })}
            maxLength={10}
          />

<div className="" >
            <label htmlFor="Designation">Designation</label>
            <select
              id="Designation"
              value={watch("designation")}
              onChange={(e) => setValue("designation", e.target.value)}
              {...register("designation", {
                required: "This field is required",
              })}
            >
              <option value="">Select Designation</option>
              <div value=''> </div>
              {designationList && designationList?.length > 0
                ? designationList?.map((rowdata, index) => (
                    <option key={rowdata._id} value={rowdata._id}>
                      {rowdata.name}
                    </option>
                  ))
                : null}
            </select>
            {errors.designation && (
              <p className="error-message">{errors.designation.message}</p>
            )}
           
          </div>
          {/* <div className="form-group" style={{ width: "100%" }}> */}
            <label htmlFor="designation">Role:</label>
            <input
              type="text"
              id="role"
              name="role"
              {...register("role", {
                required: {
                  value: true,
                  message: "Role is required",
                },
                maxLength: {
                  value: 25,
                  message: "Maximum 25 characters allowed",
                },
                minLength: {
                  value: 5,

                  message: "Minimum 5 characters required",
                },
              })}
            />
            {errors.role && (
              <p className="error-message">{errors.role.message}</p>
            )}
          {/* </div> */}
          <label className="form-label">Salary Per Month</label>
          <Input
            type="number"
            className="form-control"
            {...register("salaryPerMonth", {
              pattern: {
                value: /^[0-9\b]+$/i,
                message: "Only numbers are allowed",
              },
              maxLength: {
                value: 10,
                message: "Maximum 10 characters allowed",
              },

              minLength: {
                value: 4,
                message: "Minimum 4 characters required",
              },
            })}
          />
          <DialogFooter>
            <DialogClose asChild>
              <Button onClick={onDelate} variant="destructive">
                Delete
              </Button>
            </DialogClose>

            {formState.isValid && (
              <DialogClose asChild>
                <Button type="submit">Save</Button>
              </DialogClose>
            )}

            {!formState.isValid && <Button type="submit">Save</Button>}
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
